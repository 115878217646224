import React, { useEffect, useState } from 'react';

import { Divider, List, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';

import PermissionsError from '../Errors/Permission.js';

import 'antd/dist/reset.css';

import { convertToLocalTimezone } from '../../Helpers/Utils.js';
import { getNotificationsListUrl } from '../../Helpers/User.js';

import ListPagination from '../Utils/ListPagination.js';

const contentStyle = { textAlign: 'center' };
const listStyle = {
  width: '40%',
  border: '1px solid #bfbfbf',
  backgroundColor: '#ffffff'
};
const pageStyle = { alignItems: 'center' };
const seenElementStyle = {
   backgroundColor: '#d3d3d3'
};

function NotificationList(props) {
  let { userId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const [error, setError] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(urlParams.get("items_per_page")
    ? urlParams.get("items_per_page") : 15);
  const [pageIndex, setPageIndex] = useState(urlParams.get("page_index")
    ? urlParams.get("page_index") : 1);
  const { updateNewNotificationsCount } = props;

  userId = userId === undefined ? '' : userId;

  useEffect(() => {
    let url = getNotificationsListUrl(userId, pageIndex, itemsPerPage);
    fetch(url).then(response => response.json()).then(data => {
      if (data.success === 1) {
        updateNewNotificationsCount(null);
        setNotifications(data.user_notifications);
        setTotalNotifications(data.notifications_count);
        history.push(`/user/${userId}/notifications`
          + `?page_index=${pageIndex}&items_per_page=${itemsPerPage}`);
      } else {
        setError(data.error);
      }
    })
  }, [userId, itemsPerPage, pageIndex, history, updateNewNotificationsCount]);

  function onPageIndexChange(newPageIndex) {
    setPageIndex(newPageIndex);
  }

  function onItemsNumberChange(newItemsNumber) {
    setItemsPerPage(newItemsNumber);
  }

  return (
    <>
    {error
      ? <PermissionsError />
      : <Layout style={pageStyle}>
          <Layout style={listStyle}>
            <List
              itemLayout="horizontal"
              dataSource={notifications}
              renderItem={item => (
                <List.Item style={item.seen ? seenElementStyle : null}>
                  <List.Item.Meta style={contentStyle}
                    title=<Link to={`${item.link}`}>{t(item.body)}</Link>
                    description={convertToLocalTimezone(item.date_sent)}
                  />
                </List.Item>
              )}
            />
          </Layout>
          <Divider/>
          <Layout>
            <ListPagination
              itemsNumber={itemsPerPage}
              onItemsNumberChange={onItemsNumberChange}
              onPageIndexChange={onPageIndexChange}
              pageIndex={pageIndex}
              totalItems={totalNotifications}
            />
          </Layout>
        </Layout>
    }
    </>
  );
}

export default NotificationList;
