export async function getComments(codeId) {
  const fetchCommentsPromise = await fetch('/api/comments/' + codeId)
    .then(response => response.json());
  return fetchCommentsPromise;
}

/**
 * Define the comment box cancel button for each AddComment component
 * depending if the comment is a code line comment, a reply comment or none.
 */
export function getCancelButtonId(lineNumber, parentCommentId) {
  return lineNumber
    ? `cancel-add-codeline-comment-${lineNumber}` : parentCommentId
    ? `cancel-add-reply-comment-${parentCommentId}` : 'cancel-button';
}

/**
 * Define the comment textarea id for each AddComment component
 * depending if the comment is a code line comment, a reply comment or none.
 * Receives as parameter the code line number and the parent comment id.
 */
export function getCommentTextAreaId(lineNumber, parentCommentId) {
  return lineNumber
    ? `comment-codeline-textarea-${lineNumber}` : parentCommentId
    ? `comment-reply-textarea-${parentCommentId}` : "comment-textarea";
}

/**
 * Define the post comment button id for each AddComment component
 * depending if the comment is a code line comment, a reply comment or none.
 * Receives as parameter the code line number and the parent comment id.
 */
export function getPostCommentButtonId(lineNumber, parentCommentId) {
  return lineNumber
    ? `post-comment-button-codeline-${lineNumber}` : parentCommentId
    ? `post-comment-button-reply-${parentCommentId}` : "post-comment-button";
}

function initProperty(obj, key, defaultValue) {
  const has = Object.prototype.hasOwnProperty;
  if (!has.call(obj, key)) {
    obj[key] = defaultValue;
  }
  return obj[key];
}

/**
 * Receives an array containing comments objects and extracts in a new hash
 * the code line comments.
 * The hash will have as keys the line indexes and as values arrays containing
 * the comments on the line with the index equals to the key.
 * A code line comment is a comment that has a defined line_number.
 */
export function getCodeLinesComments(comments) {
  let codeLinesComments = {};
  for (let comment of comments.filter(comment => comment.line_number !== null)) {
    let commentsList = initProperty(codeLinesComments, comment.line_number, []);
    commentsList.push(comment);
  }
  return codeLinesComments;
}

/**
 * Receives the id of a comment object and fetches the BE function in order to
 * delete the comment with the given id.
 * It returns a JSON response.
 */
export function submitDeleteComment(commentId) {
  return fetch('/api/comment/delete',
    {
      method: 'POST',
      body: JSON.stringify({id: commentId})
    }
  ).then(response => response.json());
}

/**
 * Receives the id and the new body of a comment object and fetches the BE
 * function in order to edit the body of the comment with the given id.
 * It returns a JSON response.
 */
export function submitEditComment(commentId, newCommentBody) {
  return fetch('/api/comment/edit',
    {
      method: 'POST',
      body: JSON.stringify({
        id: commentId,
        body: newCommentBody
      })
    }
  ).then(response => response.json());
}
