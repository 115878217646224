import React from 'react';

import { Layout } from 'antd';

function Footer() {
  const { Footer } = Layout;
  
  return(
    <Footer style={{ textAlign: 'center' }}>
      © 2017-{(new Date().getFullYear())} WellCode PB S.R.L. B-dul. 
      Nicolae Titulescu, nr. 27, Cluj-Napoca, Cluj, România, 400407
    </Footer>
  );
}

export default Footer;
