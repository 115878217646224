import React from 'react';
import { PlusSquareTwoTone } from '@ant-design/icons';

import './View.css';

/**
 * CodeLine
 *   - renders a code line
 *   - a code line is represented by its index, the plus button
 *     and the highlighted text which represents the code
 * Receives
 *   - code - highlighted text that represents a code line
 *   - index - index of the code line
 *   - toggleLineCommentBoxVisibility - function that updates the visibility
 *                                      of the comment box for a code line
 */
function CodeLine(props) {
  return (
    <tr key={props.index} className="line">
      <td className="line-number">{props.index + 1}</td>
      <td id={"plus" + props.index} className="plus-square-line">
        <PlusSquareTwoTone className="plus-square"
          onClick={() => props.toggleLineCommentBoxVisibility(props.index + 1)} />
      </td>
      <td
        className="code-row" dangerouslySetInnerHTML={{__html: props.code}}>
      </td>
    </tr>
  );
}

export default CodeLine;
