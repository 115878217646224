// Creates the user notifications list url based on the given params
export function getNotificationsListUrl(userId, pageIndex, itemsPerPage) {
  let url = `/api/user-notifications/${userId}/list`;
  url = pageIndex ? url + `?page_index=${pageIndex}` : url;
  url = itemsPerPage ? url + `&items_per_page=${itemsPerPage}` : url;
  return url;
}

export async function getUserNewNotifications() {
  return await fetch('/api/new-notifications')
    .then(response => response.json());
}
