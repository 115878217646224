import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function Logout(props) {
  const history = useHistory();

  useEffect(() => {
    fetch('/api/logout')
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          props.onLogoutSuccess().then(() => {
            history.push('/login');
          });
        }
      })
  }, [props, history]);

  return (null);
}

export default Logout;
