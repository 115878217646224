import React from 'react'

import { Cascader } from 'antd';
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/ext-modelist";
import { useTranslation } from 'react-i18next';

function filter(inputValue, path) {
  return path.some(option =>
    option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

function ChooseLanguage(props) {
  const { t } = useTranslation();

  var modelist = ace.require("ace/ext/modelist");
  let options = modelist.modes.map(
    function(x) {
      return { value: x.name, label: x.caption };
    }
  );

  return (
    <Cascader
      options={options}
      onChange={props.onLanguageChange}
      placeholder={t('Select programming language')}
      showSearch={{ filter }}
    />
  );
}

export default ChooseLanguage;
