import React from 'react';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Badge, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

function Navbar(props) {
  const { t } = useTranslation();

  return (
    <Menu mode="horizontal">
      <Menu.Item key="createCode">
        <Link to="/code/add">{t('Add code')}</Link>
      </Menu.Item>
      {props.userData.id
        ? <Menu.Item key="codeFeed">
            <Link to="/code/feed">{t('Code feed')}</Link>
          </Menu.Item>
        : null
      }
      {props.userData.id
        ? <SubMenu title={props.userData.id}>
            <Menu.Item key="myCodes">
              <Link to={`/code/feed/${props.userData.id}`}>
                {t('My codes')}
              </Link>
            </Menu.Item>
            <Menu.Item key="logOut">
              <Link to="/logout">{t('Log out')}</Link>
            </Menu.Item>
            {props.userData.role === 'ADMIN' && (
              <Menu.Item key="resetPassword">
                <Link to="/user/admin-reset-password">
                  {t(`Reset user password`)}
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        : <Menu.Item key="logIn">
            <Link to="/login">{t('Log in')}</Link>
          </Menu.Item>
      }
      {props.userData.id
        ? <Menu.Item key="notifications">
            <Link to={`/user/${props.userData.id}/notifications`}>
              <Badge count={props.newNotificationsCount}>
                <GlobalOutlined />
              </Badge>
            </Link>
          </Menu.Item>
        : <Menu.Item key="register">
            <Link to="/register">{t('Register')}</Link>
          </Menu.Item>
      }
    </Menu>
  );
}

export default Navbar;
