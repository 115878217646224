import React, { useEffect, useState }  from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, Layout, Space } from 'antd';
import { BranchesOutlined, CheckCircleOutlined, ClockCircleOutlined, CloseOutlined,
  UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';

import { changeCodeAssignee, changeCodeReviewStatus }
  from '../../Helpers/Code.js';

/**
 * ReviewStatus
 * - renders a card that contains info about code review status and buttons for
 * actions that change code review status
 * Receives as props
 *   - code object
 *   - userData object containing user's session data
 */
function ReviewStatus(props) {
  const { t } = useTranslation();
  const [codeAssignee, setCodeAssignee] = useState('');
  const [codeReviewStatus, setCodeReviewStatus] = useState('');

  let requestCodeReviewButton = null;
  /**
   * Button for request/cancel code review. This button change the review status
   * to NOT_REQUESTED/PENDING.
   * Displayed for code author.
   */
  if (props.userData.id === props.code.author) {
    let icon = <CloseOutlined />;
    let status = 'NOT_REQUESTED';
    let text = 'Cancel code review request';
    let style = { background: "red", borderColor: "red" };

    if (codeReviewStatus === 'NOT_REQUESTED' || codeReviewStatus === 'SOLVED') {
      icon = <BranchesOutlined />;
      status = 'PENDING';
      text = 'Request code review';
      style = null;
    }

    requestCodeReviewButton =
      <Button
        id="request-code-review-button"
        type="primary"
        icon={icon}
        style={style}
        onClick={() => onChangeCodeReviewStatus(status)}
      >
        {t(text)}
      </Button>;
  }

  let markCodeReviewButton = null;
  /**
   * Button for mark code review status as SOLVED/PENDING.
   * Displayed for admin users.
   */
  let assignCodeReviewButton = null;
  /**
   * Button for assign code review.
   * Displayed for admin users.
   */
  if (props.userData.role === 'ADMIN') {
    let icon = <CheckCircleOutlined />;
    let status = 'SOLVED';
    let text = 'Mark as solved';
    let style = { background: "green", borderColor: "green" };

    if (codeReviewStatus === 'SOLVED') {
      icon = <ClockCircleOutlined />;
      status = 'PENDING';
      style = { background: "yellow", borderColor: "yellow", color: "black" };
      text = 'Mark as pending';
    }

    markCodeReviewButton =
      <Button
        id="mark-code-review-button"
        type="primary"
        icon={icon}
        style={style}
        onClick={() => onChangeCodeReviewStatus(status)}
      >
        {t(text)}
      </Button>;

    let userId = null;
    icon = <UserDeleteOutlined />;
    text = 'Unassign code review';
    style = { background: "red", borderColor: "red" };

    if (codeAssignee === '' || codeAssignee === null) {
      userId = props.userData.id;
      icon = <UserAddOutlined />;
      text = 'Assign code review';
      style = null;
    }

    assignCodeReviewButton =
      <Button
        id="assign-code-button"
        type="primary"
        icon={icon}
        style={style}
        onClick={() => onChangeCodeAssignee(userId)}
      >
        {t(text)}
      </Button>;
  }

  function onChangeCodeReviewStatus(newCodeReviewStatus) {
    changeCodeReviewStatus(props.code.id, newCodeReviewStatus).then(data => {
      if (data.success === 1) {
        setCodeReviewStatus(newCodeReviewStatus);
      }
    });
  }

  function onChangeCodeAssignee(newAssignee) {
    changeCodeAssignee(props.code.id, newAssignee).then(data => {
      if (data.success) {
        setCodeAssignee(newAssignee);
      }
    });
  }

  useEffect(() => {
    setCodeAssignee(props.code.assignee ? props.code.assignee : '');
    setCodeReviewStatus(props.code.review_status);
  }, [props]);

  return (
    <Layout className="container">
      <Space direction="vertical">
        <Card title={`${t('Author')}: ${props.code.author}`}>
          <p>{`${t('Title')}: ${props.code.title || 
            props.code.id}`}</p>
          <p>{`${t('Code review status')}: ${t(codeReviewStatus)}`}</p>
          <p>{`${t('Code review assignee')}: `
            + `${codeAssignee ? codeAssignee : '' }`}
          </p>
          <Space direction="vertical">
            {requestCodeReviewButton}
            {markCodeReviewButton}
            {assignCodeReviewButton}
          </Space>
        </Card>
      </Space>
    </Layout>
  );
}

export default ReviewStatus;
