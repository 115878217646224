import React, { useState } from 'react';
import { Button, Input, Layout, Row, Space, Col } from 'antd';
import AceEditor from 'react-ace';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-sql';
import ChooseLanguage from './ChooseLanguage';

function AddCode() {
  const { t } = useTranslation();
  const history = useHistory();

  var reactAceComponent;
  const [language, setLanguage] = useState("cpp");
  const [code, setCode] = useState("Your code here");
  const [title, setTitle] = useState("");
  const buttonStyle = {textAlign: 'right'};
  const containerStyle = {width: '60%'};
  const editorStyle = {border: '1px solid #bfbfbf'};
  const pageStyle = {alignItems: 'center'};

  function onLanguageChange(languageId) {
    setLanguage(languageId[0]);
    reactAceComponent.editor.getSession().setMode("ace/mode/" + language);
  }

  function onCodeChange(newCode) {
    setCode(newCode);
  }

  function onTitleChange(newTitle) {
    setTitle(newTitle.target.value);
  }

  function submitCode() {
    let request = {
      language: language,
      source_code: code,
      title: title,
    };
    fetch('/api/code/add', {
      method: 'POST',
      body: JSON.stringify(request)
    }).then(response => response.json())
    .then(data => {
      if (data.success === 1) {
        history.push('/code/' + data.code.id + '/view');
      }
    });
  }

  return (
    <Layout style={pageStyle}>
      <Layout style={containerStyle}>
        <Space direction="vertical">
          <Row>
            <Col span={5}>
              <ChooseLanguage onLanguageChange={onLanguageChange} />
            </Col>
            <Col span={19}>
              <Input
                placeholder={t('Write a title')}
                value={title}
                onChange={onTitleChange}
                id="problemName"
              />
            </Col>
          </Row>
          <Layout style={editorStyle}>
            <AceEditor
              width= "100%"
              ref={ (component) => reactAceComponent = component }
              mode={language}
              theme="github"
              onChange={onCodeChange}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: true }}
              value={code}
            />
          </Layout>
          <div style={buttonStyle}>
            <Button 
              type="primary" 
              id="submit-button" 
              onClick={submitCode} 
            >
              {t('Submit')}
            </Button>
          </div>
        </Space>
      </Layout>
    </Layout>
  );
}

export default AddCode;
