import React, { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Form, Input, Layout, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

function Login(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const [alertDescription, setAlertDescription] =
    useState(location.state ?
      location.state.alertDescription : '');
  const [alertType, setAlertType] = useState('success');
  const history = useHistory();

  const onFinish = values => {
    fetch('/api/login',
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    ).then(response => response.json())
    .then(data => {
      if (data.success === 1) {
        props.onLoginSuccess().then(() => {
          if (location.state) {
            history.push(location.state.from.pathname);
          }
        });
      } else {
        setAlertType('error');
        setAlertDescription(data.error);
      }
    });
  };

  const pageStyle = { alignItems: 'center' };

  return (
    <Layout style={pageStyle}>
      <Card>
        {alertDescription &&
          <Alert
            description={t(alertDescription)}
            type={alertType}
            showIcon
            closable
          />
        }
        <Space> </Space>
        <Form
          name="login"
          className="login-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="id"
            rules={[{ required: true,
              message: t('Please input your username!') }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('Username')} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true,
              message: t('Please input your password!') }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('Password')}
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              id="login-button"
            >
              {t('Log in')}
            </Button>
            {t('or')}
            <a href="/register">{t('register now')}!</a>
          </Space>
        </Form>
      </Card>
    </Layout>
  );
}

export default Login;
