export async function getCode(codeId) {
  const fetchCodePromise = await fetch('/api/code/' + codeId + '/view')
    .then(response => response.json());
  return fetchCodePromise;
}

// translates ace mode to highlight.js mode
export function translateLanguage(language) {
  if (language === 'c_cpp') {
    return 'cpp';
  }
  return language;
}

export async function changeCodeReviewStatus(codeId, newStatus) {
  return await fetch(`/api/code/${codeId}/change-status`, {
    method: 'POST',
    body: JSON.stringify({ status: newStatus })
  }).then(response => response.json());
}

export async function changeCodeAssignee(codeId, newAssignee) {
  return await fetch(`/api/code/${codeId}/assign`, {
    method: 'POST',
    body: JSON.stringify({ user_id: newAssignee })
  }).then(response => response.json());
}

export async function getCodesAssignees() {
  return await fetch(`/api/codes/get-assignees`)
    .then(response => response.json());
}

/**
 * The code must be divided into separate lines so that each line will be
 * part of a separate table row.
 * Making this division,  some spans will not be closed on the proper line
 * (for example for code comments).
 * This function will store the opened spans of each line.
 * If a line has been interrupted by splitting into more lines, we will add
 * to the beginning of each new line, the stored opened spans, and we will
 * close all the opened spans which were not closed.
 */
export function multiLineHighlight(code) {
  let rows = [];
  let openTags = [];
  code.split("\n").forEach((line) => {
    line = openTags.join('').concat(line);
    openTags = getOpenedTags(line);
    line += closeAllOpenedTags(openTags.length);
    rows.push(line);
  });
  return rows;
}

// Close all the opened tags
export function closeAllOpenedTags(openedTags) {
  let lineSufix = '';
  for (let i = 0; i < openedTags; ++i) {
    lineSufix += '</span>';
  }
  return lineSufix;
}

// Check if there are opened tags which are not closed and store them
export function getOpenedTags(line) {
  let openTags = [];
  for (let i = 0; i < line.length; ++i) {
    if (line[i] === '<') {
      let tag = '';
      for (; line[i] !== '>'; ++i) {
        tag += line[i];
      }
      tag += '>';
      tag[1] === '/' ? openTags.pop() : openTags.push(tag);
    }
  }
  return openTags;
}

// Creates the code feed url based on the given params
export function getCodeFeedUrl(userId, pageIndex, itemsPerPage,
  reviewStatus, assignee) {
  let url = `/api/code/feed?user_id=${userId}`;
  url = pageIndex ? url + `&page_index=${pageIndex}` : url;
  url = itemsPerPage ? url + `&items_per_page=${itemsPerPage}` : url;
  url = reviewStatus ? url + `&review_status=${reviewStatus}` : url;
  url = assignee ? url + `&assignee=${assignee}` : url;
  return url;
}
