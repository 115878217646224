import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { getCancelButtonId, getCommentTextAreaId, getPostCommentButtonId }
  from '../../Helpers/Comment.js';

/**
 * AddComment
 *   - renders the form for adding a comment
 * Receives
 *   - codeId - id of the code
 *   - isVisible - 1 if the add comment section is visible,
 *                 0 or undefined otherwise
 *   - lineNumber - index of a code line; can be undefined
 *   - parentCommentId - id of the parent comment; can be undefined
 *   - updateComments - function that updates the comments by re-rendering
 *                      the ViewCode component after a comment
 *                      is added successfully
 */
function AddComment(props) {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [comment, setComment] = useState();
  const [isVisible, setIsVisible] = useState();

  const buttonStyle = { float: 'right' };
  const formStyle = { maxWidth: '50rem' };

  /**
   * Set visibility state with the props received
   */
  useEffect(() => {
    setIsVisible(props.isVisible);
  }, [props.isVisible]);

  function onCommentChange(newComment) {
    setComment(newComment.target.value);
  }

  function resetCommentInput() {
    setComment('');
  }

  function onFormReset() {
    form.resetFields();
  }

  function submitComment() {
    let request = {
      body: comment,
      code_id: props.codeId,
      line_number: props.lineNumber,
      parent_comment_id: props.parentCommentId
    };
    fetch('/api/comment/add',
      {
        method: 'POST',
        body: JSON.stringify(request)
      }
    ).then(response => response.json())
    .then(data => {
      if (data.success === 1) {
        if (props.lineNumber || props.parentCommentId) {
          setIsVisible(0);
        }
        props.updateComments();
        resetCommentInput();
      }
    });
  }

  return (
    <>
    {isVisible === 1
      ? <Form form={form} name="comment" className="comment-form"
          onFinish={submitComment} style={formStyle}>
          <Form.Item>
            <Form.Item name="body" label={t('Comment')}>
              <TextArea placeholder={t('Leave a comment')}
                onChange={onCommentChange}
                id={getCommentTextAreaId(props.lineNumber,
                  props.parentCommentId)}
              />
            </Form.Item>
            <Form.Item style={buttonStyle}>
              <Space>
                <Button type="primary" htmlType="submit"
                  id={getPostCommentButtonId(props.lineNumber,
                    props.parentCommentId)}
                  className = "comment-form-button" onClick={onFormReset}>
                  {t('Post')}
                </Button>
                {props.lineNumber || props.parentCommentId
                  ? <Button
                      id={getCancelButtonId(props.lineNumber,
                        props.parentCommentId)}
                      type="secondary"
                      className="comment-form-button"
                      onClick={() => setIsVisible(0)}>
                      {t('Cancel')}
                    </Button>
                  : null
                }
              </Space>
            </Form.Item>
          </Form.Item>
        </Form>
      : null
    }
    </>
  );
}

export default AddComment;
