import React, { useState } from 'react';

import { Avatar, message, Popconfirm, Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import 'antd/dist/reset.css';
import { DeleteTwoTone } from '@ant-design/icons';
import { submitDeleteComment, submitEditComment }
  from '../../Helpers/Comment.js';
import { useTranslation } from 'react-i18next';

import AddComment from './Add.js';
import CommentsList from './List.js';
import './CommentCard.css';

/**
 * CommentCard
 * - used to display a comment
 * Receives
 *   - comment data with the following props
 *     - body
 *     - codeId
 *     - commentIndex
 *     - commentReplies - array containing comments objects which are the
 *                        replies of a parent comment
 *     - datePosted
 *     - id - the id of the comments list used for unit testing the parent
 *            comments and its replies
 *     - parentCommentId
 *     - userId - the author's id of the comment
 *   - isCodeLineComment - 1 if is a code line comment, 0 otherwise
 *   - isParentComment - defined if the displayed comment is a parent comment,
 *                       undefined otherwise
 *   - updateComments - function that updates the comments by re-rendering the
 *                      ViewCode component after a comment is added successfully
 */

function CommentCard(props) {
  const [replyCommentBoxVisibility, setReplyCommentBoxVisibility] = useState([]);
  const { t } = useTranslation();
  const { Text } = Typography;

  let commentStyle = props.isParentComment === null? "parentComment" : "";
  commentStyle = "commentCard " + commentStyle;

  function toggleReplyBoxVisibility(commentIndex) {
    var auxState = { ...replyCommentBoxVisibility };
    auxState[commentIndex] = auxState[commentIndex] ? 0 : 1;
    setReplyCommentBoxVisibility(auxState);
  }

  function deleteComment(commentId) {
    submitDeleteComment(commentId).then(data => {
      if (data.success === 1) {
        props.updateComments();
      } else {
        message.error('You do not have enough permissions for this action!');
      }
    });
  }

  function editComment(newCommentBody) {
    submitEditComment(props.parentCommentId, newCommentBody).then(data => {
      if (data.success === 1) {
        props.updateComments();
      } else {
        message.error(data.error);
      }
    });
  }

  /**
   * Used for memorizing the actions of a comment.
   * Array with action items rendered below the comment body which are:
   *   - the ‘Reply to’ button for triggering the AddComment component
   *   - the delete icon button for deleting a comment
   */
  const actions = [
    <>
    {props.isParentComment === null
      ? <span
          id={"reply-button-" + props.commentIndex}
          onClick={() => toggleReplyBoxVisibility(props.commentIndex)}>
          {t('Reply to')}
        </span>
      : null
    }
    {props.userData.role === 'ADMIN' || props.userData.id === props.userId
      ? <Popconfirm
          title="Do you want to delete this comment？"
          okText="Yes" cancelText="No"
          onConfirm={() => deleteComment(props.parentCommentId)}>
          <DeleteTwoTone id={"delete-comment-button-" + props.commentIndex}/>
        </Popconfirm>
      : null
    }
    </>
  ];

  /**
   * Used for memorizing conditioned rendered components which are
   * the children of a comment.
   * Array with children items rendered below the comment body which are:
   *   - the AddComment component for adding a reply to a parent comment
   *   - the CommentsList component for displaying the replies of a parent
   *       comment
   */
  const children = [
      props.isParentComment === null && props.commentReplies.length
        ? <CommentsList
            codeId={props.codeId}
            comments={props.commentReplies}
            key={"replies-list-" + props.commentIndex}
            updateComments={props.updateComments}
            userData={props.userData}
          />
        : null,
      <AddComment
        codeId={props.codeId}
        isVisible={replyCommentBoxVisibility[props.commentIndex]}
        key={"reply-box-" + props.commentIndex}
        parentCommentId={props.parentCommentId}
        updateComments={props.updateComments}
      />
  ];

  /**
   * Used for memorizing conditioned rendered comment content.
   * If the session user is ADMIN or it's the author of the comment, then the
   * Text component will be rendered so the comment body can be edited.
   * Otherwise, the comment body will be rendered.
   */
  const commentContent = (
    props.userData.role === 'ADMIN' || props.userData.id === props.userId
    ? <Text editable={{ onChange: editComment }}
        id={"edit-comment-" + props.commentIndex}>
        {props.body}
      </Text>
    : props.body
  );

  return (
    <Comment
      className={commentStyle}
      actions={actions}
      author={props.userId}
      avatar={<Avatar
          src="http://learn.wellcode.ro/img/user.png"
        />
      }
      children={children}
      content={commentContent}
      datetime={props.datePosted}
      id={props.id}
    />
  );
}

export default CommentCard;
