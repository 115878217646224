import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PermissionsError from '../Errors/Permission.js';

import 'antd/dist/reset.css';
import { Divider, Layout, List } from 'antd';

import { convertToLocalTimezone } from '../../Helpers/Utils.js';
import { getCodeFeedUrl } from '../../Helpers/Code.js';

import CodeFeedFilter from './FeedFilter.js';
import ListPagination from '../Utils/ListPagination.js';

const contentStyle = { textAlign: 'center' };
const listStyle = {
  width: '40%',
  border: '1px solid #bfbfbf',
  backgroundColor: '#ffffff'
};
const pageStyle = { alignItems: 'center' };

function CodeFeed(props) {
  let { userId } = useParams();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const [codeFeed, setCodeFeed] = useState([]);
  const [error, setError] = useState("");
  const [totalCodes, setTotalCodes] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(urlParams.get("items_per_page")
    ? urlParams.get("items_per_page") : 15);
  const [pageIndex, setPageIndex] = useState(urlParams.get("page_index")
    ? urlParams.get("page_index") : 1);
  const [codeReviewStatus, setCodeReviewStatus] =
    useState(urlParams.get('review_status') ? urlParams.get('review_status') : '');
  const [codeReviewAssignee, setCodeReviewAssignee] =
    useState(urlParams.get('assignee') ? urlParams.get('assignee') : '');

  userId = userId === undefined ? '' : userId;

  useEffect(() => {
    let url =
      getCodeFeedUrl(userId, pageIndex, itemsPerPage,
      codeReviewStatus, codeReviewAssignee);
    fetch(url).then(response => response.json()).then(data => {
      if (data.success === 1) {
        setCodeFeed(data.feed);
        setTotalCodes(data.total_codes);
        if (history.location.search === "") {
          setPageIndex(1);
          setItemsPerPage(15);
        }
        history.push(`/code/feed/${userId}`
          + `?page_index=${pageIndex}&items_per_page=${itemsPerPage}`
          + `&review_status=${codeReviewStatus}&assignee=${codeReviewAssignee}`);
      } else {
        setError(data.error);
      }
    })
  }, [userId, pageIndex, itemsPerPage, codeReviewStatus,
      codeReviewAssignee, history]);

  function onPageIndexChange(newPageIndex) {
    setPageIndex(newPageIndex);
  }

  function onItemsNumberChange(newItemsNumber) {
    setItemsPerPage(newItemsNumber);
  }

  function onCodeReviewAssigneeChange(newAssignee) {
    setCodeReviewAssignee(newAssignee);
  }

  function onCodeReviewStatusChange(newCodeReviewStatus) {
    setCodeReviewStatus(newCodeReviewStatus);
  }

  return (
    <>
    {error
      ? <PermissionsError />
      : <Layout style={pageStyle}>
          <CodeFeedFilter
            codeReviewAssignee={codeReviewAssignee}
            codeReviewStatus={codeReviewStatus}
            onCodeReviewAssigneeChange={onCodeReviewAssigneeChange}
            onCodeReviewStatusChange={onCodeReviewStatusChange}
            userData={props.userData}
          />
          <Divider />
          <Layout style={listStyle}>
            <List
              itemLayout="horizontal"
              dataSource={codeFeed}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta style={contentStyle}
                    title=<Link to={`/code/${item.id}/view`}>
                      {item.title ? item.title : item.id}
                    </Link>
                    description={convertToLocalTimezone(item.date_posted)}
                  />
                </List.Item>
              )}
            />
          </Layout>
          <Divider/>
          <Layout>
            <ListPagination
              itemsNumber={itemsPerPage}
              onItemsNumberChange={onItemsNumberChange}
              onPageIndexChange={onPageIndexChange}
              pageIndex={pageIndex}
              totalItems={totalCodes}
            />
          </Layout>
        </Layout>
    }
    </>
  );
}

export default CodeFeed;
