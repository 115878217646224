import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCodesAssignees } from '../../Helpers/Code.js';

import { Select, Space } from 'antd';

const { Option } = Select;

function CodeFeedFilter(props) {
  const { t } = useTranslation();
  const [codesAssignees, setCodesAssignees] = useState([]);

  const codesAssigneesOptions = codesAssignees
    ? codesAssignees.map((item, index) =>
        <Option key={index} value={item}>{item}</Option>
      )
    : [];

  useEffect(() => {
    if (props.userData.role === 'ADMIN') {
      getCodesAssignees().then(data => {
        if (data.success === 1) {
          setCodesAssignees(data.users);
        }
      });
    }
  }, [props.userData.role]);

  return (
    <>
    <Space>
      <Select
        id="review-status-select"
        placeholder={t('Code review status')}
        style={{ width: 200 }}
        value={props.codeReviewStatus ? props.codeReviewStatus : undefined}
        onChange={props.onCodeReviewStatusChange}
      >
        <Option value="">{t('ALL STATUS')}</Option>
        <Option value="SOLVED">{t('SOLVED')}</Option>
        <Option value="PENDING">{t('PENDING')}</Option>
        <Option value="NOT_REQUESTED">{t('NOT_REQUESTED')}</Option>
      </Select>
      {props.userData.role === 'ADMIN'
        ? <Select
            id="code-assignees-select"
            placeholder={t('Assignee')}
            style={{ width: 200 }}
            value={props.codeReviewAssignee ? props.codeReviewAssignee : undefined}
            onChange={props.onCodeReviewAssigneeChange}
          >
            <Option value=""></Option>
            <Option value="ALL_ADMINS">{t('All admins')}</Option>
            <Option value="NO_ASSIGNEE">{t('No assignee')}</Option>
            {codesAssigneesOptions}
          </Select>
        : null
      }
    </Space>
    </>
  );
}

export default CodeFeedFilter;
