import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CodeTable from './CodeTable.js';
import CommentsList from '../Comment/List.js';
import PermissionsError from '../Errors/Permission.js';
import ReviewStatus from './ReviewStatus.js';

import { Layout } from 'antd';

import { getCode } from '../../Helpers/Code.js';
import { getCodeLinesComments, getComments } from '../../Helpers/Comment.js';

const pageStyle = {
  alignItems: 'center'
};
const containerStyle = {
  width: '80%'
};

/**
 * ViewCode
 * - base component for the code review.
 * - fetches the code and the comments from the back-end and passes them down
 *   to the children components CodeTable and CommentsList
 */
function ViewCode(props) {
  let { id } = useParams();
  const [addCommentSuccess, setAddCommentSuccess] = useState(0);
  const [code, setCode] = useState([]);
  const [codeLinesComments, setCodeLinesComments] = useState({});
  const [comments, setComments] = useState([]);
  const [error, setError] = useState("");

  /**
   * This function tiggers in the child component CommentsList, after a valid
   * comment is posted, so this is causing a state change that leads to the
   * re-render of the parent component ViewCode.
   */
  function updateComments() {
    setAddCommentSuccess(addCommentSuccess + 1);
  }

  /**
   * Effect for fetching the code, called only once.
   */
  useEffect(() => {
    getCode(id).then(data => {
      if (data.success === 1) {
        setCode(data.code);
      } else {
        setError(data.error);
      }
    });
  }, [id]);

  /**
   * Effect for fetching the comments, called on first render and after
   * each addCommentSuccess state change.
   */
  useEffect(() => {
    getComments(id).then(data => {
      if (data.success === 1) {
        // Filter code line comments
        setCodeLinesComments(getCodeLinesComments(data.comments));
        // Filter the comments that belong to the code
        setComments(data.comments.filter(comment =>
          comment.line_number === null));
      } else {
        setError(data.error);
      }
    });
  }, [addCommentSuccess, id]);

  return (
    <>
    {error
      ? <PermissionsError error={error} />
      : <div>
        <ReviewStatus
          code={code}
          userData={props.userData}
        />
        {code.source_code
          ? <CodeTable
              code={code}
              codeLinesComments={codeLinesComments}
              updateComments={updateComments}
              userData={props.userData}
            />
          : null
        }
        <Layout style={pageStyle}>
          <Layout style={containerStyle}>
            <CommentsList
              addCommentVisibility={1}
              codeId={id}
              comments={comments}
              id={"comments-list"}
              updateComments={updateComments}
              userData={props.userData}
            />
          </Layout>
        </Layout>
        </div>
    }
    </>
  );
}

export default ViewCode;
