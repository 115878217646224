import React, { useCallback, useEffect, useState } from 'react';

import { Layout } from 'antd';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import './View.css';

import { translateLanguage, multiLineHighlight } from '../../Helpers/Code.js';

import CodeLine from './Line.js';
import CommentsList from '../Comment/List.js';

const rowStyle = {
  padding: '0',
  backgroundColor: 'white',
  whiteSpace: 'pre',
  width: '100%'
};

/**
 * CodeTable
 * - renders the highlighted code in a table
 * Receives
 *   - code object as props with the following keys
 *     - id
 *     - source_code
 *     - language
 *     - user_id
 *     - date_posted
 */
function CodeTable(props) {
  const [codeRows, setCodeRows] = useState([]);
  const [lineCommentBoxVisibility, setLineCommentBoxVisibility] = useState([]);

  const toggleLineCommentBoxVisibility = useCallback((lineIndex) => {
    var auxState = { ...lineCommentBoxVisibility };
    auxState[lineIndex] = auxState[lineIndex] ? 0 : 1;
    setLineCommentBoxVisibility(auxState);
  }, [lineCommentBoxVisibility]);

  useEffect(() => {
    let codeLanguage = translateLanguage(props.code.language);
    let sourceCode = props.code.source_code;
    let highlightedCode = hljs.highlight(codeLanguage, sourceCode);
    let highlightedRows = multiLineHighlight(highlightedCode.value);
    let rows = [];
    highlightedRows.forEach((line, index) => {
      rows.push(
        <React.Fragment key={index}>
          <CodeLine
            code={line}
            index={index}
            toggleLineCommentBoxVisibility={toggleLineCommentBoxVisibility}
          />
          <tr>
            <td />
            <td />
            <td id={`codeline-${index + 1}-comments`}>
              <CommentsList
                addCommentVisibility={lineCommentBoxVisibility[index + 1]}
                codeId={props.code.id}
                comments={props.codeLinesComments[index + 1]}
                isCodeLineComment={1}
                updateComments={props.updateComments}
                userData={props.userData}
                lineNumber={index + 1}
              />
            </td>
          </tr>
        </React.Fragment>
      );
    });
    setCodeRows(rows);
  }, [props, lineCommentBoxVisibility, toggleLineCommentBoxVisibility]);

  return (
    <Layout className="container">
      <table cellSpacing="0" cellPadding="0" style={rowStyle}>
        <tbody>
          {codeRows}
        </tbody>
      </table>
    </Layout>
  );
}

export default CodeTable;
