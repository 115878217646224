import React from 'react';

import 'antd/dist/reset.css';
import { List } from 'antd';

import AddComment from './Add.js';
import CommentCard from './CommentCard.js';

import { convertToLocalTimezone } from '../../Helpers/Utils.js';

const listStyle = { maxWidth: '50rem' };

/**
 * CommentsList
 * - renders the comments list
 * Receives as props
 *   - array with comments objects
 *     - each comment object has the following keys
 *       - user_id
 *       - date_posted
 *       - body
 *       - id - the id of the comments list used for unit testing the parent
 *              comments and its replies
 *   - addCommentVisibility - 1 if the AddComment component should be displayed,
 *                            0 or undefined otherwise
 *   - codeId - the id of the code
 *   - isCodeLineComment - 1 if the comments are code line comments, 0 otherwise
 *   - lineNumber - code line number; can be undefined
 *   - parentCommentId - id of the parent comment; can be undefined
 *   - updateComments - function that updates the comments by re-rendering
 *                      the ViewCode component after a comment
 *                      is added successfully
 */
function CommentsList(props) {
  return (
    <>
    {props.comments
      ? <List
          dataSource={props.comments}
          itemLayout="horizontal"
          style={listStyle}
          renderItem={(comment, index) => (
            <List.Item>
              <CommentCard
                addCommentVisibility={props.addCommentVisibility}
                body={comment.body}
                codeId={props.codeId}
                commentIndex={index}
                commentReplies={comment.replies}
                datePosted={convertToLocalTimezone(comment.date_posted)}
                id={props.id}
                isCodeLineComment={props.isCodeLineComment}
                isParentComment={comment.parent_comment_id}
                parentCommentId={comment.id}
                updateComments={props.updateComments}
                userData={props.userData}
                userId={comment.user_id}
              />
            </List.Item>
          )}
        />
      : null
    }
    <AddComment
      codeId={props.codeId}
      isVisible={props.addCommentVisibility}
      updateComments={props.updateComments}
      lineNumber={props.lineNumber}
      userData={props.userData}
    />
    </>
  );
}

export default CommentsList;
