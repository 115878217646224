import React from 'react';

import { useTranslation } from 'react-i18next';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

function PermissionsError(props) {
  const { t } = useTranslation();
  return (
    <Result
      status="403"
      title="403"
      subTitle={t('You are not authorized to access this page!')}
      extra={
        <Link to="/">
          <Button type="primary">{t('Back home')}</Button>
        </Link>
      }
    />
  );
}

export default PermissionsError;
