import React  from 'react';
import { Pagination } from 'antd';

/**
 * ListPagination
 *  - renders the Pagination component for a list of items
 * Receives
 *  - totalItems - the total number of items
 *  - itemsNumber - the number of items per page
 *  - pageIndex - the number of page
 *  - onPageIndexChange - function that changes the value of page index
 *  - onItemsNumberChange - function that changes the number of items per page
 */

function ListPagination(props) {
  const pageSizeOptions = ["5", "10", "15", "25", "50", "100"];

  function onPageSizeChange(current, pageSize) {
    props.onItemsNumberChange(pageSize);
  }

  function onPageNumberChange(pageNumber) {
    pageNumber = pageNumber ? pageNumber : 1;
    props.onPageIndexChange(pageNumber);
  }

  return (
    <>
      <Pagination
        current={props.pageIndex}
        defaultCurrent={1}
        defaultPageSize={15}
        onChange={onPageNumberChange}
        onShowSizeChange={onPageSizeChange}
        pageSize={props.itemsNumber}
        pageSizeOptions={pageSizeOptions}
        showSizeChanger
        total={props.totalItems}
      />
    </>
  );
}

export default ListPagination;
