import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, Form, Input, Layout, Row } from 'antd';

function Register() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [error, setError] = useState('');
  const history = useHistory();

  const onSuccessLocation = {
    pathname: '/login',
    state: { alertDescription: 'You have successfully registered!' }
  }

  const onFinish = values => {
    delete values.confirm;
    setError('');
    fetch('/api/register',
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    ).then(response => response.json())
    .then(data => {
      if (data.success === 1) {
        history.push(onSuccessLocation);
      } else {
        setError(data.error);
      }
    })
    .catch (error => setError('Internal server error'));
  };

  const buttonStyle = {textAlign: 'right'};
  const pageStyle = {alignItems: 'center'};

  return (
    <Layout style={pageStyle}>
      <Card>
        {error &&
          <Alert description={t(error)} type="error" showIcon closable />}
        <Row>
          <h1>{t('Register your account')}</h1>
        </Row>
        <Row>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
          >
            <Form.Item label={t('Username')} name="id"
              rules={[
                {
                  required: true,
                  message: t('Please input your username!'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('First name')} name="first_name"
              rules={[
                {
                  required: true,
                  message: t('Please input your first name!'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('Last name')} name="last_name"
              rules={[
                {
                  required: true,
                  message: t('Please input your last name!'),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label={t('Password')}
              rules={[
                {
                  min: 10,
                  required: true,
                  message: t('Password must be at least 10 characters!'),
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label={t('Confirm password')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('Please confirm your password!'),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      t('The two passwords that you entered do not match!')
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="mail"
              label={t('email')}
              rules={[
                {
                  type: 'email',
                  message: t('Invalid email address!'),
                },
                {
                  required: true,
                  message: t('Please input your email!'),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div style={buttonStyle}>
              <Button id="register-button" type="primary" htmlType="submit">
                {t('Register')}
              </Button>
            </div>
          </Form>
        </Row>
      </Card>
    </Layout>
  );
}

export default Register;
