import React, { useCallback, useEffect, useState } from 'react';

import { Route,  Switch } from "react-router-dom";
import { Layout, Divider } from 'antd';

import './App.css';
import AddCode from './Components/Code/Add.js';
import CodeFeed from './Components/Code/Feed';
import Footer from './Components/UI/Footer.js';
import LoggedInRoute from './Components/Permissions/LoggedInRoute.js';
import Login from './Components/User/Login';
import Logout from './Components/User/Logout';
import Navbar from './Components/UI/Navbar.js';
import Register from './Components/User/Register';
import ResetPassword from './Components/User/ResetUserPassword';
import ViewCode from './Components/Code/View';
import NotificationList from './Components/User/NotificationList';

import { getUserNewNotifications } from './Helpers/User.js';

function App() {
  const { Content } = Layout;
  const [userData, setUserData] = useState({});
  const [newNotificationsCount, setNewNotificationsCount] = useState(null);

  // App should render only after the fetch for user session data is done.
  const [shouldRender, setShouldRender] = useState(false);

  const pageContainer = {
    position: 'relative',
    minHeight: '100vh'
  };

  function onSetUserSession(id, role) {
    setUserData({ id: id, role: role });
  }

  const setUserSession = useCallback(async () => {
    const sessionDataResponse = await fetch('/api/session-data')
      .then(response => response.json())
      .then(data => data);
    if (sessionDataResponse.success === 1) {
      const userSession = sessionDataResponse.session;
      userSession.user_id ?
        window.sessionStorage.setItem('userId', userSession.user_id) :
          window.sessionStorage.removeItem('userId');
      userSession.user_role ?
        window.sessionStorage.setItem('userRole', userSession.user_role) :
          window.sessionStorage.removeItem('userRole');
      onSetUserSession(userSession.user_id, userSession.user_role);
    } else {
      // TODO! Render error 500
    }
  }, []);

  function getUserData() {
    return {
      id: window.sessionStorage.getItem('userId'),
      role: window.sessionStorage.getItem('userRole')
    };
  }

  useEffect(() => {
    /**
     * Wait until user session is updated, and then set rendering state as true,
     * so component can render.
     */
    setUserSession().then(() => {
      setShouldRender(true);
    });
  }, [shouldRender, setUserSession]);

  useEffect(() => {
    // Check for new notifications
    getUserNewNotifications().then(data => {
      setNewNotificationsCount(data.notifications_count);
    });

    // Check for new notifications every 30 seconds
    const timer = setInterval(() => {
      getUserNewNotifications().then(data => {
        setNewNotificationsCount(data.notifications_count);
      });
    }, 30000);
    return () => clearInterval(timer);
  }, [userData]);

  return (
    <>
    {shouldRender
      ? <Layout style = {pageContainer}>
          <Navbar
            newNotificationsCount={newNotificationsCount}
            userData={userData}
          />
          <Divider/>
          <Content>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/about" component={About} />
              <Route path="/login">
                <Login onLoginSuccess={setUserSession} />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <LoggedInRoute userData={getUserData()} path="/code/add">
                <AddCode />
              </LoggedInRoute>
              <LoggedInRoute userData={getUserData()} path="/user/admin-reset-password">
                <ResetPassword userData={getUserData()} />
              </LoggedInRoute>
              <LoggedInRoute userData={getUserData()} path="/code/:id/view">
                <ViewCode userData={getUserData()} />
              </LoggedInRoute>
              <LoggedInRoute userData={getUserData()} path="/code/feed/:userId">
                <CodeFeed userData={getUserData()} />
              </LoggedInRoute>
              <LoggedInRoute userData={getUserData()} path="/code/feed">
                <CodeFeed userData={getUserData()} />
              </LoggedInRoute>
              <LoggedInRoute
                userData={getUserData()}
                path="/user/:userId/notifications"
              >
                <NotificationList
                  updateNewNotificationsCount={setNewNotificationsCount}
                />
              </LoggedInRoute>
              <LoggedInRoute userData={userData} path="/logout">
                <Logout onLogoutSuccess={setUserSession} />
              </LoggedInRoute>
            </Switch>
          </Content>
          <Divider/>
          <Footer />
        </Layout>
      : null
    }
    </>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h1>About</h1>;
}

export { App };

export default App;
