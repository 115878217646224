import React, { useState } from 'react';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import PermissionsError from '../Errors/Permission.js';

function ResetPassword(props) {
  const { t } = useTranslation();
  const [id, setUserId] = useState("");

  function ResetUserPassword() {
    fetch(`/api/user/${id}/admin-reset-password`, {
      method: 'GET'
    }
    ).then(response => response.json())
    .then(data => {
      if (data.success === 1) {
        alert(data.password);
      } else {
        alert('Invalid username!');
      }
    });
  }

  return (
    <>
      { props.userData && props.userData.role !== 'ADMIN' ? (
        <PermissionsError />
      ) : (
        <Flex 
          vertical="true"
          align="center"
          gap="small">
          <input
            type="text"
            placeholder={t('Enter username')}
            value={id}
            onChange={(e) => setUserId(e.target.value)}
          />
          <Button
            type="primary"
            shape="round"
            id="reset"
            danger="true"
            onClick={ResetUserPassword}
          >
            {t('Reset user password')}
          </Button>
        </Flex>
      )}
    </>
  );
}

export default ResetPassword;
